<template>
    <div id="AccountingClosingCostCenter">
        <v-container fluid app>
            <ZnapTable ref="znapTable"
                :endpoint="endpoint"
                :options="options"
                :tableRows="tableRows"
                :noData="noData"
                @setNoData="setNoData"
                :noDataMessage="noDataMessage"
                @setNoDataMessage="setNoDataMessage"
                :filterConditions="filterConditions"
                @setFilterConditions="setFilterConditions"
                @clearFilters="setFilterConditions"
                :loadTable="loadTable"
                @setLoadTable="setLoadTable"
                @clearNotRequiredOptionsItems="clearNotRequiredOptionsItems"
            >
                <!-- // Filters -->
                <template v-slot:filters>
                    <ZnapFilters ref="znapFilters"
                        :filterComponents="options"
                        :noData="noData"
                        @setSelection="populateFilterConditions"
                        :clearFilters="clearFilters"
                        @setClearFilters="setClearFilters"

                        @setFilterValue="setFilterValue"
                        @clearFilteredBy="clearFilteredBy"
                    >
                    </ZnapFilters>
                </template>
            </ZnapTable>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue'
import ZnapTable from '@/components/znap/ZnapTable.vue'
import ZnapFilters from '@/components/znap/Filters.vue'

export default {
    name:'AccountingClosingCostCenter',

    components: { ZnapTable, ZnapFilters },

    computed: {
        endpoint() {
            return [
                this.$ipAccount,
                'accounting-closing-cost-center',
                {
                    conditions: []
                }
            ]
        },
    },

    data() {
        return {
            tableRows: 10000,
    
            options: [
                {
                    column: 'id_accounting_closing_type',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Tipo do fechamento contábil',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipAccount, 'accounting-closing-type' ],
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 14
                        // }
                    ]
                },
                {
                    column: 'id_company_group',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Grupo empresarial',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipOrganization, 'company-group' ],
                    filters: ['id_cost_center'],
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 14
                        // }
                    ]
                },
                {
                    column: 'id_cost_center',
                    is: 'ComboBox',
                    items: [],
                    multiple: false,
                    label: 'Centro de custo',
                    required: false,
                    filterable: false,
                    endpoint: [ Vue.prototype.$ipOrganization, 'cost-center' ],
                    filteredBy: ['id_company_group'],
                    filteredItems: [],
                    conditions: [
                        // {
                        //     AndOr: 'AND',
                        //     column: 'id_module',
                        //     operator: '=',
                        //     value: 14
                        // }
                    ]
                },
            ],
    
            noDataMessage: '',
            noData: true,
    
            // Filters
            filterConditions: [],
            loadTable: false,
            clearFilters: false,

        }
    },

    methods: {
        async setFilterValue(e) {
            e.filters.forEach(async f => {
                let option = this.options.find(o => o.column === f)
                if (option) {
                    if (e.value.selected) {
                        let filter = {
                            conditions: [
                                {
                                    AndOr: 'AND',
                                    column: e.value.column,
                                    operator: '=',
                                    value: e.value.selected.id
                                }
                            ]
                        }
    
                        try {
                            const res = await this.$http.post(option.endpoint[0] + option.endpoint[1] + '/list-options', { filter })
                            if (res) {
                                option.filteredItems = res.data.rows
                            }
                        } catch (err) {
                            this.$fnError(err)
                        }
                    } else {
                        option.filteredItems = []
                    }

                }
            })
        },

        clearFilteredBy() {
            this.options.forEach(async o => {
                if (o.filteredBy) {
                    o.filteredItems = []
                }
            })
        },
        
        populateFilterConditions(selectionArray, requiredFilter) {
            this.filterConditions = []

            if (requiredFilter) {
                this.filterConditions.push({
                    AndOr: 'AND',
                    column: selectionArray[0].column,
                    operator: '=',
                    value: selectionArray[0].id,
                    required: true
                })

                this.loadTable = true
            } else {
                if (selectionArray.length !== 0) {
                    selectionArray.forEach(selection => {
                        
                        let required = false
                        this.options.forEach(option => {    
                            if (option.required && selection.column === option.column) {
                                required = true
                            }
                        })

                        if (selection.id === undefined) {
                            this.filterConditions = []
                        } else {
                            if (selection.id.length > 1) {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: 'IN',
                                    value: selection.id,
                                    required
                                })
                            } else {
                                this.filterConditions.push({
                                    AndOr: 'AND',
                                    column: selection.column,
                                    operator: '=',
                                    value: selection.id,
                                    required
                                })
                            }             
                        }
                    })
                }
            }
        },

        setNoDataMessage(message) {
            this.noDataMessage = message
        },

        setNoData(payload) {
            this.noData = payload
        },

        setFilterConditions(array) {
            this.filterConditions = array
            
            if (array.length === 0) {
                this.clearFilters = true
            }

            this.options.forEach(option => {
                if (option.required) {
                    this.loadTable = true
                } 
            })
        },

        setClearFilters(payload) {
            this.clearFilters = payload
        },

        clearNotRequiredOptionsItems() {
            this.options.forEach(option => {
                if (!option.required) {
                    option.items = []
                } 
            })
        },

        setLoadTable(payload) {
            this.loadTable = payload
        }
    },
}
</script>

<style>
</style>